import React, {useEffect, useState} from 'react';
import {getCreditInstallmentFees, updateUserFees} from 'services/feeService'; // Certifique-se de ajustar o caminho da importação
import CustomInput from 'components/CustomInput/CustomInput';
import PrimaryButton from 'components/DashboardComponents/PrimaryButton/PrimaryButton';
import './AdminCreditTax.css';

const AdminCreditTax = () => {
  const [fees, setFees] = useState([]);
  const userId = 1; // Substitua pelo ID do usuário apropriado

  useEffect(() => {
    const fetchFees = async () => {
      try {
        const data = await getCreditInstallmentFees(userId);
        setFees(data);
      } catch (error) {
        console.error('Erro ao buscar taxas de parcelas:', error);
      }
    };

    fetchFees();
  }, [userId]);

  const handleSave = async () => {
    const acquirerName = fees[0]?.acquirerName; // Supondo que todos os fees tenham o mesmo acquirerName
    const feeValues = fees.map(fee => parseFloat(fee.feePercentage)); // Convertendo para decimal

    if (feeValues.length !== 12) {
      alert('É necessário fornecer 12 valores de taxas.');
      return;
    }

    const payload = {
      userId,
      acquirerName,
      fees: feeValues,
    };

    try {
      await updateUserFees(payload);
      alert('Taxas atualizadas com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar taxas:', error);
      alert('Erro ao atualizar taxas.');
    }
  };

  return (
    <div className="tax-container">
      <div className="tax-column">
        {fees.map((fee, index) => (
          <div key={`${fee.acquirerName}-${index}`}>
            <label>{`${fee.installments}x`}</label>
            <CustomInput
              type="text"
              value={fee.feePercentage}
              onChange={(e) => {
                const updatedFees = [...fees];
                const feeIndex = updatedFees.findIndex(
                  (f) => f.acquirerName === fee.acquirerName && f.installments === fee.installments
                );
                if (feeIndex !== -1) {
                  updatedFees[feeIndex].feePercentage = e.target.value;
                }
                setFees(updatedFees);
              }}
              errorMessage={fee.errorMessage}
            />

          </div>
        ))}
      </div>
      <PrimaryButton
        label="Salvar"
        onClick={handleSave}></PrimaryButton>
    </div>
  );
};

export default AdminCreditTax;